import './contact.css';
import Navbar from '../navbar';
import Footer from '../footer';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// make sure to install emailjs browser by doing npm i '@emailjs/browser'


function Contact() {
    const navigate = useNavigate();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_dkfk25q', 'template_3raoll8', form.current, 'PWXMEA0LLH8cXQH0_')

      .then((result) => {
          console.log(result.text);
          navigate ('/contact_reply');
      }, (error) => {
          console.log(error.text);
      });
  };



    return (
        <><Navbar />
        <form ref={form} onSubmit={sendEmail}>
        <div className='about-header'>
            <h1 id='about-header'>Contact</h1>
        </div>
        <div className='contact-page'>
            <div>
                <h1 id='contact-input' >Name</h1>
                <div className='input-boxes'>
                <input name='name' type="text" />
                </div>
                
            </div>
            <br />
            <div>
                <h1 id='contact-input' >Email</h1>
                <input name='email' type="text" />
            </div>
            <br />
            <div>
                <h1 id='contact-input' >Phone</h1>
                <input name='phone' type="text" />
            </div>
            <br />
            <div>
                <h1 id='contact-input' >Message</h1>
                <h3>(In a few words describe your condition)</h3>
                <br />
                <textarea id='contact-input2' maxlength="300" rows='4' type="text" name='message' label="Message" />
            </div>
            <br />
            <div>
                <button>Submit</button>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div>
                <h1>Phone</h1>
                <h3>706-383-9734</h3>
            </div>
            <br />
            <div>
                <h1>Hours</h1>
                <h3>M - F 9 to 5pm</h3>
            </div>
        </div>
        </form>
        </>
    )
}


export default Contact;