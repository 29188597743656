import './faq.css';
import Navbar from '../navbar';
import Footer from '../footer';


function Faq() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>FAQ</h1>
        </div>
        <div className='faq'>
            <h1>Best way to contact?</h1>
            <h3>By phone 706-383-9734
or email tom@restoremobilept.com</h3>
<br />
            <h1>Do I need a physician referral to get started?</h1>
            <h3>No. In Georgia, direct access to physical therapy is permitted, and you can schedule an appointment with a licensed physical therapist without a physician referral. This gives increasing access to physical therapy services, reducing barriers to care,  allowing individuals to receive prompt treatment for musculoskeletal conditions, injuries, or rehabilitation needs. It is recommended to see your physician before or during your care to rule out other medical conditions.</h3>
            <br />
            <h1>What are your rates?</h1>
            <h3>Please contact us for our rates. </h3>
            <br />
            <h1>What payments are accepted?</h1>
            <h3>Check, Credit Cards, HSA and FSA cards are accepted.</h3>
            <br />
            <h1>Do you take my health insurance?</h1>
            <h3>No. We are a cash-based physical therapy service. However, upon request, at the end of your time, we are able to give you an invoice that you can provide to your insurance company for possible reimbursement. Please check with your insurance company.</h3>
            <br />
            <h1>Why choose a cash-based physical therapist over an in network provider?</h1>
            <h3>Cash-based therapy gives you more control over your treatment plan. You and your therapist can collaboratively decide on the frequency and duration of sessions, tailoring the care to your unique needs rather than adhering to insurance-mandated limitations. Cash-based therapy allows the provider to perform longer one-on-one treatment sessions. There will be no handing off to other clinicians during your therapy session that commonly occurs at your local clinic. With our model of care, we provide consistency and a stronger therapist-patient relationship that can improve outcomes. Cash-based therapists also have the flexibility to incorporate holistic or alternative therapies that insurance providers may not cover, providing a more comprehensive approach to your treatment.</h3>
        </div>
        <Footer /></>
    )
}


export default Faq;